import React from 'react'
import "./Home.css";
import { appName } from '../../context/constants';

const Navbar = ({setSection}) => {
  
  return (
   <header className="text-white body-font md:block hidden">
  <div className="container px-4 mx-auto flex flex-wrap py-4 flex-col md:flex-row items-center">
    {/* image logo */}
      {/* <img src='/logo.png' alt='logo' className=' lg:w-60 w-52' /> */}
      {/* --------------- */}
      {/* text logo */}
    <p className="flex title-font font-extrabold maintext items-center text-white text-3xl mb-4 md:mb-0" style={{lineHeight: "40px", margin: 0, cursor: "pointer"}}>
      {/* <img src='/logo.png' alt='logo' className=' lg:w-60 w-52' /> */}
      {appName}
    </p>
    <nav className="lg:mr-0 md:mx-auto px-2 md:mr-0 flex flex-wrap items-center text-base">
      <p onClick={() => {setSection("service")}} className="lg:mr-5 md:mr-4 mr-2 hover:text-[white] transition-all cursor-pointer nav__links" style={{color: "white", textDecoration: "none"}}>Services</p>
      <p onClick={() => {setSection("reviews")}} className="lg:mr-5 md:mr-4 mr-2 hover:text-[white]  transition-all cursor-pointer nav__links" style={{color: "white", textDecoration: "none"}}>Client Reviews</p>
      {/* <a href='/#pricing' className="lg:mr-5 md:mr-4 mr-2 hover:text-[white]  transition-all cursor-pointer">Pricing</a> */}
      <p onClick={() => {setSection("faq")}} className="lg:mr-5 md:mr-4 mr-2 hover:text-[white] transition-all cursor-pointer nav__links" style={{color: "white", textDecoration: "none"}}>FAQs</p> {/*hover:scale-100 */}
    </nav>
    
  </div>
</header>

  )
}

export default Navbar