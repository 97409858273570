import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import { Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import process from "../../process";
import { ChatpdfContext } from "../../context/Context";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadItem from "./UploadItem";
import SearchIcon from "@mui/icons-material/Search";
import { TbFileAlert } from "react-icons/tb";
import { ColorContext } from "../../context/ThemeContext";
import { LuSearch } from "react-icons/lu";

function Dashboard(props) {
  const navigate = useNavigate();
  const { CustomColors } = useContext(ColorContext);
  const { currentUser, handleFetchErrors } = useContext(ChatpdfContext);
  const [status, setStatus] = useState("fetch-loading");
  const [uploadList, setUploadList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  //fetch-loading, results, no-results, error, filtered, filtered-no-results

  useEffect(() => {
    if (!currentUser) return;
    fetchUploads();
  }, [currentUser]);

  const fetchUploads = async () => {
    setStatus("fetch-loading");
    try {
      let res = await fetch(`${process.env.baseUrl}/uploads`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.userId,
        }),
      });
      res = await res.json();
      // console.log(res);

      if (res?.error || res?.showError) {
        setStatus("error");
        return handleFetchErrors(res);
      }
      setUploadList(res);
      if (res.length) {
        setStatus("results");
      } else {
        setStatus("no-results");
      }
    } catch (e) {
      handleFetchErrors(e);
      setStatus("error");
    }
  };

  const searchHandler = (uploadObj, value) => {
        let fileList = uploadObj.files;
        for (let file of fileList) {
          let fileName = file.name.toLowerCase();
          if (fileName.includes(value)) {
            return true;
          }
        }
        return false;
      }

  const handleSearchChange = (e) => {
    let value = e.target.value;
    setSearchKey(value);
    value = value.trim();
    if (!value.length) {
      if (uploadList.length) {
        setStatus("results");
      } else {
        setStatus("no-results");
      }
    } else {
      let filtered = uploadList.filter((fileObj) => searchHandler(fileObj, value));
      if (filtered.length) {
        setFilteredList(filtered);
        setStatus("filtered");
      } else {
        setStatus("filtered-no-results");
      }
    }
  };

  const filterDeletedUpload = async (chainId) => {
    await fetchUploads();
  }


  const handleDeleteUpload = async (chainId) => {
    try {
      let res = await fetch(`${process.env.baseUrl}/delete_upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId: currentUser.userId,
          chainId: chainId,
          dateDeleted: Date.now().toString()
        })
      });
      res = await res.json();
      // console.log(res);
      if (res?.error || res?.showError) return handleFetchErrors(res);
      await filterDeletedUpload();
    } catch (e) {
      handleFetchErrors(e);
    }
  }


  return (
    <div className="dash_container">
      {/* <Button
        variant="text"
        onClick={() => {
          navigate("/upload");
        }}
      >
        Upload
      </Button>
      <Button
        variant="text"
        onClick={() => {
          // navigate("/qa/54a0c2a6-ac10-4507-9ec8-31074441209a");
          navigate("/qa/e34a8410-c55a-49a6-89fa-d5b59ddcf921");
        }}
      >
        chat
      </Button> */}
      <div className="dash_wrapper">
        <div className="dash_header">
          <h2 className="dash_title">Documents</h2>
          <div className="dash_search">
            <div className="dash_search_container">
              <SearchIcon sx={{ margin: "0 15px" }} />
              <input
                value={searchKey}
                onChange={handleSearchChange}
                className="dash_search_input"
                placeholder="Search..."
              />
            </div>
            <Button
              variant="contained"
              size="large"
              style={{
                textTransform: "none",
                fontSize: "16px",
                fontFamily: "Nunito Sans",
                borderRadius: "22px",
              }}
              onClick={() => {
                navigate("/upload");
              }}
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </div>
        </div>

        {status === "results" ? (
          <div className="dash_document_list">
            {uploadList.map((item) => (
              <UploadItem key={item.chainId} uploadObj={item} handleDeleteUpload={handleDeleteUpload} />
            ))}
          </div>
        ) : status === "fetch-loading" ? (
          <>
            <Skeleton
              variant="rounded"
              width="90%"
              height={13}
              sx={{ margin: "15px auto 5px" }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={13}
              sx={{ margin: "0 auto 5px" }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={13}
              sx={{ margin: "0 auto 5px" }}
            />
            <Skeleton
              variant="rounded"
              width="90%"
              height={13}
              sx={{ margin: "0 auto 5px" }}
            />
          </>
        ) : status === "no-results" ? (
          <div className="dash_document_list flex_center">
            <div className="no_result_wrapper">
              <TbFileAlert size={60} color={CustomColors.primary} />
              <p>You have no documents. Click upload to upload</p>
            </div>
          </div>
        ) : status === "filtered-no-results" ? (
          <div className="dash_document_list flex_center">
            <div className="no_result_wrapper">
              <LuSearch size={60} color={CustomColors.primary} />
              <p>{`Document with name '${searchKey}' not found`}</p>
            </div>
          </div>
        ) : status === "filtered" ? (
          <div className="dash_document_list">
            {filteredList.map((item) => (
              <UploadItem key={item.chainId} uploadObj={item} handleDeleteUpload={handleDeleteUpload} />
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
