import React, { useContext } from "react";
import Logo from "../Logo/Logo";
import { LinearProgress } from "@mui/material";
import "../../pages/Starter/Starter.css";
import { LogoIcon } from "../Icon/Icon";
import { ColorContext } from "../../context/ThemeContext";

function Loader(props) {
  const { CustomColors } = useContext(ColorContext);
  return (
    <div className="starter_container">
      <div className="starter_wrapper">
        <LogoIcon size={40} style={{ color: CustomColors.scrollbar }} />
        <Logo
          textStyle={{
            // textAlign: "center",
            margin: "20px 0",
            fontSize: "1.5rem",
            fontFamily: "Mooli",
          }}
        />
        <LinearProgress sx={{ width: "100%" }} />
      </div>
    </div>
  );
}

export default Loader;
