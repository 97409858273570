import React, { createContext, useState } from "react";
import { uuid4 as v4 } from "uuid4";
import process from "../process";
import { storage } from "./firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export const ChatpdfContext = createContext();

export const ChatpdfProvider = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [currentChain, setCurrentChain] = useState(null);
  const [conversationList, setConversationList] = useState([]);
  const [highlightList, setHighlightList] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [scrollPage, setScrollPage] = useState("");

  const signin = async (userId, displayFail = true) => {
    try {
      let res = await fetch(`${process.env.baseUrl}/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          dateCreated: Date.now().toString(),
        }),
      });
      res = await res.json();
      // console.log(res);
      if (res?.error) {
        if (displayFail) handleFetchErrors(res);
        return { error: res.error };
      }
      if (res?.showError) {
        if (displayFail) handleFetchErrors(res);
        return { error: res.showError };
      }
      setCurrentUser(res?.user);
      await storeUserId(res?.user.userId);
      if (res.message.includes("signup")) {
        return { firstSignin: true };
      } else {
        return { firstSignin: false };
      }
    } catch (e) {
      if (displayFail) handleFetchErrors(e);
      return { error: e };
    }
  };

  const storeUserId = async (userId) => {
    localStorage.setItem("ud", userId);
  };

  const handleSignout = async () => {
    setCurrentUser(null);
    localStorage.removeItem("ud");
  };

  const handleFetchErrors = (res = false) => {
    if (res?.error) {
      // console.log(res.error);
      setSnackbarState({
        message: "An error occured!",
        severity: "error",
        open: true,
      });
    } else if (res?.showError) {
      setSnackbarState({
        message: res.showError,
        severity: "error",
        open: true,
      });
    } else {
      // console.log(res);
      setSnackbarState({
        message: "An error occured!",
        severity: "error",
        open: true,
      });
    }
    return false;
  };

  const createChain = async (fileDetails) => {
    try {
      let res = await fetch(`${process.env.baseUrl}/upload`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser?.userId,
          fileDetails: fileDetails,
          dateCreated: Date.now().toString(),
          fileObjs: [
            {
              file_type: "pdf",
              file_urls: fileDetails.map((obj) => ({
                url: obj.url,
                fileId: obj.fileId,
              })),
            },
          ],
        }),
      });
      res = await res.json();
      // console.log(res);
      if (res?.error || res?.showError) return handleFetchErrors(res);
      setCurrentChain(res);
      return res.chainId;
    } catch (e) {
      handleFetchErrors(e);
      return false;
    }
  };

  const addToConversationList = (obj, content, chainId) => {
    if (!obj) {
      obj = {
        content,
        chainId,
        dateCreated: Date.now().toString(),
        userId: currentUser.userId,
        role: "user",
        chatId: v4(),
      };
    }

    setConversationList((prev) => [...prev, obj]);
  };

  const fetchAnswer = async (question, chainId) => {
    if (!currentUser) return;
    let questionObj = {
      content: question,
      chainId,
      dateCreated: Date.now().toString(),
      userId: currentUser.userId,
      role: "user",
      chatId: v4(),
    };
    setConversationList((prev) => [...prev, questionObj]);
    try {
      let res = await fetch(`${process.env.baseUrl}/query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: currentUser.userId,
          chainId,
          query: question,
          questionObj,
        }),
      });
      res = await res.json();
      // console.log(res);
      if (res?.showError || res?.error) return handleFetchErrors(res);
      setConversationList((prev) => [...prev, res]);
    } catch (e) {
      return handleFetchErrors(e);
    }
  };

  const fetchChats = async (chainId) => {
    setConversationList([]);
    try {
      let res = await fetch(
        `${process.env.baseUrl}/chats?userId=${currentUser.userId}&chainId=${chainId}`
      );
      res = await res.json();
      // console.log(res);

      if (res?.error || res?.showError) return handleFetchErrors(res);
      setConversationList(res);
    } catch (e) {
      return handleFetchErrors(e);
    }
  };

  const clearChats = () => {
    setConversationList([]);
  };

  const uploadFile = async (
    fileDetails,
    endProcess,
    directory = "avatars",
    setProgress = (val) => {}
  ) => {
    const storageRef = ref(
      storage,
      `${directory}/${fileDetails.id + fileDetails.file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, fileDetails.file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        handleFetchErrors(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          endProcess(downloadURL, fileDetails.id);
        });
      }
    );
  };

  return (
    <ChatpdfContext.Provider
      value={{
        setSnackbarState,
        snackbarState,
        signin,
        currentUser,
        setCurrentUser,
        handleSignout,
        uploadFile,
        createChain,
        fetchAnswer,
        conversationList,
        fetchChats,
        handleFetchErrors,
        highlightList,
        setHighlightList,
        clearChats,
        selectedFileUrl,
        setSelectedFileUrl,
        scrollPage,
        setScrollPage,
      }}
    >
      {children}
    </ChatpdfContext.Provider>
  );
};
