import { colors } from "@mui/material";

const customColors = {
  black: "#000000",
  dark: "#1F1E1C",
  textColor: "#FFF",
  pearlWhite: "#E2DFD2",
  lightBlue: "#add8e6",
  mattBlack: "rgb(40, 40, 43)",
  onyx: "rgb(53, 57, 53)",
  info: "#0288D1",
  grey900: "#212121",
  grey800: "#424242",
  grey700: "#616161",
  grey600: "#757575",
  grey500: "#9E9E9E",
  grey400: "#BDBDBD",
  grey300: "#E0E0E0",
  grey200: "#EEE",
  grey100: "#F5F5F5",
  grey50: "#FAFAFA",
  pureWhite: "#FFFFFF",
  error: "rgb(255, 51,51)",
  backgroundDark: "#121212",
};

export const lightColors = {
  backgroundColor: customColors.pureWhite,
  textColor: customColors.dark,
  headingColor: customColors.dark,
  hr: customColors.grey400,
  scrollbar: customColors.grey400,
  chatInputBorder: customColors.grey300,
  error: "rgb(255, 51,51)",
  aiChatBackground: customColors.grey100,
  lightText: customColors.grey600,
  backgroundHover: customColors.grey200,
  primary: colors.blue["700"],
  transparent: "rgba(0,0,0,0)",
};

export const darkColors = {
  backgroundColor: customColors.backgroundDark,
  textColor: customColors.pureWhite,
  headingColor: customColors.pureWhite,
  hr: customColors.grey400,
  scrollbar: customColors.grey400,
  chatInputBorder: customColors.grey700,
  error: "rgb(255, 51,51)",
  aiChatBackground: customColors.grey800,
  lightText: customColors.pureWhite,
  backgroundHover: customColors.grey800,
  primary: colors.blue["200"],
  transparent: "rgba(0,0,0,0)",
};
