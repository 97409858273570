import React, { useContext, useEffect, useState } from "react";
import { ChatpdfContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { LinearProgress, Paper, ThemeProvider } from "@mui/material";

import { ColorContext } from "../../context/ThemeContext";
import Navbar from "../../components/Navbar/Navbar";
import { LogoIcon } from "../../components/Icon/Icon";
import Loader from "../../components/Loader/Loader";

function AuthWrapper({ Page, inverse }) {
  const { currentUser, signin } = useContext(ChatpdfContext);
  const { theme, CustomColors } = useContext(ColorContext);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    handleAuth();
  }, [Page]);

  const handleAuth = async () => {
    setIsAuth(false);
    if (inverse && currentUser) {
      navigate("/documents");
      return;
    }
    if (inverse && !currentUser) {
      setIsAuth(true);
      return;
    }
    if (currentUser) {
      setIsAuth(true);
      return;
    }

    const uid = localStorage.getItem("ud");
    if (!uid) {
      navigate("/home");
      return;
    }
    let res = await signin(uid);
    if (res?.error) {
      navigate("/auth");
      return;
    } else {
      setIsAuth(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          height: "100vh",
          overflowY: "hidden",
          borderRadius: "0",
          backgroundColor: CustomColors.backgroundColor,
        }}
      >
        <Navbar />
        {isAuth ? <Page /> : <Loader />}
      </Paper>
    </ThemeProvider>
  );
}

export default AuthWrapper;
