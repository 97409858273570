import React, { useContext, useEffect, useState } from "react";
import "./UploadPage.css";
import BaseUI from "./BaseUI";
import { FileUploader } from "baseui/file-uploader";
import { ChatpdfContext } from "../../context/Context";
import { uuid4 as v4 } from "uuid4";
import FilePdf from "./FilePdf";
import { Button, CircularProgress } from "@mui/material";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function UploadPage(props) {
  const navigate = useNavigate();
  const { setSnackbarState, createChain } = useContext(ChatpdfContext);
  const [selected, setSelected] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadResult, setUploadResult] = useState([]);

  useEffect(() => {
    onSuccessfulUpload();
  }, [uploadResult]);

  const onSuccessfulUpload = async () => {
    if (uploadResult.length > 0 && uploadResult.length === selected.length) {
      // console.log(uploadResult);
      setUploadStatus("Training AI...")
      let chain = await createChain(uploadResult);
      setUploadLoading(false);
      setUploadResult([]);
      setSelected([]);
      if (chain) navigate(`/qa/${chain}`);
    }
  }

  const onSelectFiles = (files) => {
    let lst = [];
    for (let file of files) {
      let fileObj = { file, name: file.name, size: file.size, id: v4() };
      lst.push(fileObj);
    }
    let newSelected = selected.concat(lst);
    setSelected(newSelected);
    // console.log(newSelected);
  };

  

  const handleRemove = (fileId) => {
    let newSelected = selected.filter(file => file.id !== fileId);
    setSelected(newSelected);
  };

  const onDropRejected = (files) => {
    setSnackbarState({
      severity: "error",
      message: "Only pdf files allowed!",
      open: true,
    });
  };

  const handleUpload = async () => {
    if (uploadLoading) return;
    setUploadLoading(true);
    setUploadStatus("Uploading files...")
  }

  return (
    <div className="upload_container">
      <BaseUI>
        <div className="upload_stage">
          <FileUploader
            accept="application/pdf"
            multiple={true}
            onDropAccepted={onSelectFiles}
            onDropRejected={onDropRejected}
          />
        </div>
      </BaseUI>
      {selected.length ? (
        <div className="status_container flex_center">
          <Button
            size="large"
            variant={uploadLoading ? "outlined" : "contained"}
            startIcon={<IoCloudUploadOutline />}
            endIcon={uploadLoading ? <CircularProgress size={20} /> : <></>}
            style={{
              borderRadius: "30px",
              fontFamily: "Nunito Sans",
              textTransform: "none",
            }}
            onClick={handleUpload}
          >
            {uploadLoading ? uploadStatus : "Upload"}
          </Button>
        </div>
      ) : (
        <></>
      )}
      <div className="files_container">
        {selected.map((file) => (
          <FilePdf
            key={file.id}
            handleRemove={handleRemove}
            fileObj={file}
            uploading={uploadLoading}
            setUploadResult={setUploadResult}
          />
        ))}
      </div>
    </div>
  );
}

export default UploadPage;
