import React, { useContext } from "react";
import "./Profile.css";
import { ChatpdfContext } from "../../context/Context";
import { Avatar, Button } from "@mui/material";
import { ColorContext } from "../../context/ThemeContext";
import { Time } from "../../context/constants";
import Signout from "./Signout";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Profile(props) {
  const { currentUser } = useContext(ChatpdfContext);
  const { CustomColors } = useContext(ColorContext);

  return (
    <div className="profile_container">
      <div className="profile_wrapper">
        <div className="profile_left">
          <Avatar
            src={currentUser?.photoUrl}
            alt={currentUser?.displayName}
            sx={{
              backgroundColor: CustomColors.aiChatBackground,
              width: 96,
              height: 96,
            }}
          />
        </div>
        <div className="profile_right">
          <div className="profile_item">
            <div className="profile_item_left flex_center">
              <PersonIcon />
            </div>
            <div className="profile_item_right">
              <h3>Name</h3>
              <p>{currentUser?.displayName}</p>
            </div>
          </div>
          <div className="profile_item">
            <div className="profile_item_left flex_center">
              <EmailIcon />
            </div>
            <div className="profile_item_right">
              <h3>Email</h3>
              <p>{currentUser?.email}</p>
            </div>
          </div>
          <div className="profile_item">
            <div className="profile_item_left flex_center">
              <CalendarMonthIcon />
            </div>
            <div className="profile_item_right">
              <h3>Member since</h3>
              <p>{Time.getDateTime(currentUser?.dateCreated)}</p>
            </div>
          </div>
          <Signout />
        </div>
      </div>
    </div>
  );
}

export default Profile;
