import React, { useContext, useEffect, useState } from "react";
import "./WorkPage.css";
import { useParams } from "react-router-dom";
import { ColorContext } from "../../context/ThemeContext";
import PdfView from "./PdfView";
import { Chip } from "@mui/material";
import { slicedFileName } from "../../context/constants";
import { ChatpdfContext } from "../../context/Context";

function WorkLeft({ chainObj, chainLoading }) {
  const { darkMode } = useContext(ColorContext);
  const { setSelectedFileUrl, selectedFileUrl } = useContext(ChatpdfContext);
  const files = chainObj?.files;
  const [selectedUrl, setSelectedUrl] = useState(files?.[0].url);

  const path1 =
    "https://firebasestorage.googleapis.com/v0/b/chatify--chat.appspot.com/o/chatpdf_training%2Fcedac0aa-0fc6-44aa-97fb-35514872bd63LESSON%20THREE.pdf?alt=media&token=ef356161-8088-4856-8095-3df12583d832";

  const path2 =
    "https://firebasestorage.googleapis.com/v0/b/chatify--chat.appspot.com/o/chatpdf_training%2F3797495f-0c62-45f7-864e-9a1e4b268053lecture%208.pdf?alt=media&token=c72e6cc0-1320-409c-9695-f9972a39a1e8";

  const handleChipClick = (index) => {
    let selectedFile = files[index];
    // setSelectedUrl(selectedFile.url);
    setSelectedFileUrl(selectedFile.url);
  };

  return (
    <div className="work_left">
      <div className="file_chips">
        {chainObj?.files.map((file, i) => (
          <Chip
            title={file.name}
            key={i}
            color="primary"
            sx={{ marginRight: "15px" }}
            variant={selectedFileUrl === file.url ? "filled" : "outlined"}
            label={slicedFileName(file.name, 20)}
            onClick={() => handleChipClick(i)}
          />
        ))}
      </div>
      <PdfView />
    </div>
  );
}

export default WorkLeft;
