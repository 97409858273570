import { colors } from "@mui/material";
import React, { useContext } from "react";
import { FaLeaf } from "react-icons/fa";
import { ColorContext } from "../../context/ThemeContext";

function Icon({ size }) {
  const { CustomColors } = useContext(ColorContext);
  return <FaLeaf size={size} />;
}

export const LogoIcon = FaLeaf;

export default Icon;
