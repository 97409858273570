import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { maxChatChars } from "../../context/constants";
import { ChatpdfContext } from "../../context/Context";
import ChatBubble, { AIBubble } from "./ChatBubble";
import ChatInput, { EffectiveInput } from "./ChatInput";

function WorkRight({ chainObj, chainLoading }) {
  const { chainId } = useParams();
  const { fetchAnswer, conversationList, currentUser, fetchChats, clearChats } =
    useContext(ChatpdfContext);
  const [sendLoading, setSendLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [scrollBehavior, setScrollBehavior] = useState("instant");
  let inputRef = useRef();
  let scrollRef = useRef();

  useEffect(() => {
    if (!inputRef) return;
    // handleInputHeight({ currentTarget: inputRef.current });
    return () => {
      clearChats();
    };
  }, [inputRef]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView(
      // { behavior: "smooth" }
      { behavior: scrollBehavior }
    );
  }, [conversationList]);

  useEffect(() => {
    if (!chainObj || !currentUser) return;
    getChats();
  }, [chainObj]);

  const getChats = async () => {
    setFetchLoading(true);
    await fetchChats(chainObj?.chainId);
    setFetchLoading(false);
  };

  const handleInputChange = (event) => {
    let value = event.target.value;
    if (value.length > maxChatChars) return;
    setUserInput(value);
  };

  const handleInputHeight = (event) => {
    let elem = event.currentTarget;
    // console.log(elem.style.height + " " + elem.scrollHeight);
    elem.style.height = "";
    elem.style.height = elem.scrollHeight - 5 + "px";
    elem.style.maxHeight = "360px";
  };

  const handleFormChatSubmit = async (event) => {
    event.preventDefault();
    await handleSendChat();
  };

  const handleSendChat = async () => {
    if (!currentUser || sendLoading || !userInput.trim().length) return;
    let question = userInput.trim();
    setUserInput("");
    setSendLoading(true);
    setScrollBehavior("smooth");
    await fetchAnswer(question, chainId);
    setSendLoading(false);
  };

  return (
    <div className="work_right">
      <div className="work_chat_container">
        {conversationList.map((chatObj) => (
          <ChatBubble
            key={chatObj.chatId}
            chatObj={chatObj}
            fileArray={chainObj?.files}
          />
        ))}
        {sendLoading ? <AIBubble loading={true} /> : <></>}
        <div ref={scrollRef}></div>
      </div>
      <EffectiveInput
        handleFormChatSubmit={handleFormChatSubmit}
        inputRef={inputRef}
        handleInputChange={handleInputChange}
        userInput={userInput}
        handleInputHeight={handleInputHeight}
        handleSendChat={handleSendChat}
      />
    </div>
  );
}

export default WorkRight;
