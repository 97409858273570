import React, { useContext, useEffect, useState } from "react";
import "./WorkPage.css";
import { useParams } from "react-router-dom";
import WorkLeft from "./WorkLeft";
import WorkRight from "./WorkRight";
import { ChatpdfContext } from "../../context/Context";
import Loader from "../../components/Loader/Loader";
import process from "../../process";

function WorkPage(props) {
  const { currentUser, setSnackbarState, setSelectedFileUrl } = useContext(ChatpdfContext);
  const { chainId } = useParams();
  const [chainObj, setChainObj] = useState(null);
  const [chainLoading, setChainLoading] = useState(false);

  useEffect(() => {
    fetchChain();
  }, [currentUser?.userId, chainId]);

  const fetchChain = async () => {
    if (!currentUser?.userId) return;
    setChainLoading(true);
    let res = await fetch(`${process.env.baseUrl}/chain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: currentUser?.userId,
        chainId: chainId,
        timestamp: Date.now().toString(),
      }),
    });
    res = await res.json();
    // console.log(res);
    if (res?.showError) {
      setSnackbarState({
        open: true,
        message: res.showError,
        severity: "error",
      });
      return;
    }
    setChainObj(res);
    setSelectedFileUrl(res.files[0].url);
    setChainLoading(false);
  };

  if (chainLoading) {
    return <Loader />
  }

  return (
    <div className="work_container">
      <WorkLeft chainObj={chainObj} chainLoading={chainLoading} />
      <WorkRight chainObj={chainObj} chainLoading={chainLoading} />
    </div>
  );
}

export default WorkPage;
