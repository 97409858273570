import { createTheme, useMediaQuery } from "@mui/material";
import { createContext, useState } from "react";
import { darkColors, lightColors } from "./colors";

export const ColorContext = createContext();

export const ColorProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = useState(prefersDarkMode);
  const CustomColors = darkMode ? darkColors : lightColors;
  const themeName = darkMode ? "dark" : "light";
  const theme = createTheme({
    palette: {
      mode: themeName,
    },
  });
  document.documentElement.setAttribute("data-theme", themeName);
  const handleChangeColorMode = () => {
    setDarkMode((prev) => !prev);
    var currentTheme = document.documentElement.getAttribute("data-theme");
    var targetTheme = "light";

    if (currentTheme === "light") {
      targetTheme = "dark";
    }

    document.documentElement.setAttribute("data-theme", targetTheme);
  };
  return (
    <ColorContext.Provider
      value={{ theme: theme, handleChangeColorMode, darkMode, CustomColors }}
    >
      {children}
    </ColorContext.Provider>
  );
};
