import React, { useContext } from "react";
import { ChatpdfContext } from "../../context/Context";
import { ColorContext } from "../../context/ThemeContext";
import "./Logo.css";
import { appName } from "../../context/constants";
import { useNavigate } from "react-router-dom";
import Icon, { LogoIcon } from "../Icon/Icon";

function Logo({ textStyle, inline }) {
  const { CustomColors } = useContext(ColorContext);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/documents");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {inline ? <LogoIcon size={22} /> : <></>}
      <h2 onClick={handleClick} style={{ ...textStyle }} className="logo_text">
        {appName}
      </h2>
    </div>
  );
}

export default Logo;
