import React, { useState } from "react";
import { appName } from "../../context/constants";

// you can add your Client Reviews from inside square bracker just like other you are Seeing.
//Dont forget to add thier in below list that starting from line number 66



const ClientReviews = () => {
  
  const testimonial = [
    {
      id: 1,
      detail:
        `${appName} helped me go through large PDF class notes. It saved my time.`,
      name: "Gerald Otieno",
      rank: "Student at Brookehouse College",
    },
    {
      id: 2,
      detail:
        "I was able to extract important information from a company PDFs to get the best marketing points.",
      name: "Mia",
      rank: "Marketer",
    },
    {
      id: 3,
      detail:
        "Getting information from research papers has never been easier. Thanks to " + appName,
      name: "Alex",
      rank: "Researcher",
    },
  ];

  const [item, setItem] = useState(testimonial);

  const Filteritem = (id) => {
    setItem(testimonial.filter((i) => i.id == id));
  };
  return (
    <section
      id="testimonial"
      className="testimonial"
      style={{
        backgroundColor: "#07090d",
        backgroundImage:
          "radial-gradient(circle, rgba(70, 122, 255, .36), rgba(0, 0, 0, 0) 42%)",
      }}
    >
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-center capitalize lg:text-3xl text-white" style={{fontSize: "24px"}}>
          Client Reviews
        </h1>
        {/*<div className="flex justify-center mx-auto mt-6">
      <span className="inline-block w-40 h-1 bg-blue-500 rounded-full" />
      <span className="inline-block w-3 h-1 mx-1 bg-blue-500 rounded-full" />
      <span className="inline-block w-1 h-1 bg-blue-500 rounded-full" />
    </div> */}
        <div
          className="flex items-start max-w-3xl p-1 mx-auto mt-16 mb-4 rounded-2xl backdrop-blur-2xl "
          style={{
            backgroundImage:
              "linear-gradient(186deg, rgba(0, 0, 0, 0) 90%, #1976d2), linear-gradient(6deg, rgba(0, 0, 0, 0) 90%, #1976d2)",
          }}
        >
          <div
            className=" backdrop-blur-2xl w-full rounded-2xl p-10 "
            style={{
              backdropFilter: "blur(34px)",
              backgroundColor: "rgba(0, 0, 0, 0)",
              backgroundImage:
                "linear-gradient(26deg, rgba(0, 0, 0, 0) 45%, rgba(255, 255, 255, .12))",
            }}
          >
            <p className="flex items-center text-center text-white md:text-lg text-md leading-relaxed lg:mx-8">
              {item[0]?.detail}
            </p>
            <div className="flex flex-col items-center justify-center mt-8">
              <div className="mt-4 text-center">
                <h1 style={{fontSize: "16px", margin: 0}} className="font-semibold text-white">{item[0]?.name} </h1>
                <span className="text-sm text-gray-400">{item[0]?.rank}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -m-2 items-center justify-center">
          {/* box of each client with their id number in filteritem(id_number_here) */}
          <div
            onClick={() => Filteritem(1)}
            className="p-2 cursor-pointer lg:w-1/6 md:w-1/2 w-full "
          >
            <div style={{borderBottom: "1px solid rgb(229 231 235)"}} className="h-full flex items-center border-gray-200 border-b p-4">
              <div className="flex-grow text-center">
                <h2 style={{fontSize: "16px", margin: 0}} className="text-white title-font font-medium">Gerald Otieno</h2>
                <p style={{margin: 0}} className="text-gray-500">Student</p>
              </div>
            </div>
          </div>
          <div
            onClick={() => Filteritem(2)}
            className="p-2 cursor-pointer lg:w-1/6 md:w-1/2 w-full"
          >
            <div style={{borderBottom: "1px solid rgb(229 231 235)"}} className="h-full flex items-center border-gray-200 border-b p-4">
              <div className="flex-grow text-center">
                <h2 style={{fontSize: "16px", margin: 0}} className="text-white title-font font-medium">
                  Mia
                </h2>
                <p style={{margin: 0}} className="text-gray-500">Marketer</p>
              </div>
            </div>
          </div>
          <div
            onClick={() => Filteritem(3)}
            className="p-2 cursor-pointer lg:w-1/6 md:w-1/2 w-full"
          >
            <div style={{borderBottom: "1px solid rgb(229 231 235)"}} className="h-full flex items-center border-gray-200 border-b p-4">
              <div className="flex-grow text-center">
                <h2 style={{fontSize: "16px", margin: 0}} className="text-white title-font font-medium">
                  Alex Junior
                </h2>
                <p style={{margin: 0}} className="text-gray-500">Researcher</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientReviews;
