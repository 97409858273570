import React, { useContext } from "react";
import "./Navbar.css";
import Logo from "../Logo/Logo";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorContext } from "../../context/ThemeContext";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { ChatpdfContext } from "../../context/Context";
import UserImg from "./UserImg";
import { PiFilesLight } from "react-icons/pi";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";

function Navbar(props) {
  const { currentUser } = useContext(ChatpdfContext);
  const navigate = useNavigate();
  const { CustomColors } = useContext(ColorContext);
  return (
    <div className="nav_container">
      <Logo textStyle={{ marginLeft: "10px" }} inline={true} />
      <div className="nav_right">
        <ColorModeIcon />
        {currentUser ? <DocsIcon /> : <></>}
        {currentUser ? <UserImg /> : <></>}
        {/* <IconButton
          onClick={() => {
            navigate("/auth");
          }}
        >
          <ArrowForwardRoundedIcon sx={{ color: CustomColors.textColor }} />
        </IconButton> */}
      </div>
    </div>
  );
}

const DocsIcon = () => {
  const navigate = useNavigate();
  const { CustomColors } = useContext(ColorContext);
  return (
    <Tooltip title="Documents" placement="bottom">
      <IconButton
        sx={{ marginRight: "10px" }}
        onClick={() => {
          navigate("/documents");
        }}
      >
        {/* <PiFilesLight style={{ color: CustomColors.textColor }} size={25} /> */}
        <FormatListBulletedOutlinedIcon
          sx={{ color: CustomColors.textColor }}
        />
      </IconButton>
    </Tooltip>
  );
};

const ColorModeIcon = () => {
  const { darkMode, CustomColors, handleChangeColorMode } =
    useContext(ColorContext);
  return (
    <Tooltip placement="bottom" title={darkMode ? "Light mode" : "Dark mode"}>
      <IconButton sx={{ marginRight: "10px" }} onClick={handleChangeColorMode}>
        {darkMode ? (
          <LightModeIcon sx={{ color: CustomColors.textColor }} />
        ) : (
          <DarkModeOutlinedIcon sx={{ color: CustomColors.textColor }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default Navbar;
