import React, { useContext, useEffect, useState } from "react";
import { ChatpdfContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { LinearProgress } from "@mui/material";

function UserWrapper({ Page, inverse }) {
  const { currentUser, signin } = useContext(ChatpdfContext);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    fetchUser();
  }, [Page]);

  const fetchUser = async () => {
    const uid = localStorage.getItem("ud");
    if (!uid) {
      return;
    }
    let res = await signin(uid, false);
  };

  return <Page />;
}

export default UserWrapper;
