import React, { useState } from "react";
import "./UploadItem.css";
import {
  Time,
  getFileSize,
  slicedFileName,
  slicedText,
} from "../../context/constants";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

function UploadItem({ uploadObj, handleDeleteUpload }) {
  const navigate = useNavigate();

  const uploadItemClick = () => {
    navigate(`/qa/${uploadObj.chainId}`);
  };

  const getTotalFileSize = () => {
    let size = uploadObj.files.reduce(
      (prevValue, curr) => prevValue + curr.size,
      0
    );
    size = getFileSize(size);
    return size;
  };

  return (
    <div className="upload_item">
      <div className="upload_item_row1">
        {/* <div className="upload_item_text">
          <p title={uploadObj.lastChat.user?.content}>
            {slicedText(uploadObj.lastChat.user?.content, 30)}
          </p>
        </div> */}
        <div className="upload_item_files" onClick={uploadItemClick}>
          {uploadObj.files.map((fileObj, i) => (
            <div title={fileObj.name} key={i}>
              <Chip
                color="primary"
                sx={{ marginRight: "15px", marginBottom: "10px" }}
                variant="outlined"
                label={slicedFileName(fileObj.name, 20)}
              />
            </div>
          ))}
        </div>
        <div className="upload_item_size" onClick={uploadItemClick}>
          <p className="upload_item_text">{getTotalFileSize()}</p>
        </div>
        <div className="upload_item_date" onClick={uploadItemClick}>
          <p className="upload_item_text">
            {Time.relativeDate(uploadObj.dateCreated)}
          </p>
        </div>
        <div className="upload_item_actions">
          <UploadItemMenu
            uploadObj={uploadObj}
            handleDeleteUpload={handleDeleteUpload}
          />
        </div>
      </div>
      <div className="upload_item_row2">
        <div className="upload_item_date"></div>
      </div>
    </div>
  );
}

const UploadItemMenu = ({ uploadObj, handleDeleteUpload }) => {
  const [open, setOpen] = useState(false);
  const [anchorElem, setAnchorElem] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleOpen = (event) => {
    setAnchorElem(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const confirmDelete = () => {
    handleClose();
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const startDelete = async () => {
    handleDeleteClose();
    await handleDeleteUpload(uploadObj.chainId);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorElem} open={open} onClose={handleClose}>
        <MenuItem onClick={confirmDelete}>Delete</MenuItem>
      </Menu>
      <Dialog
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete document?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will not be able to access this document again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} style={{ textTransform: "none" }}>
            Cancel
          </Button>
          <Button onClick={startDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadItem;
