import React, { useContext, useEffect, useRef, useState } from "react";
import "./WorkPage.css";
import { useParams } from "react-router-dom";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/search/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";

import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { ColorContext } from "../../context/ThemeContext";
import { Button } from "@mui/material";
import { ChatpdfContext } from "../../context/Context";

function PdfView({ url }) {
  const { darkMode } = useContext(ColorContext);
  const { highlightList, selectedFileUrl, scrollPage } =
    useContext(ChatpdfContext);
  const transform = (slot) => ({
    ...slot,
    Open: () => <></>,
    OpenMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    // SwitchTheme: () => <></>,
    // SwitchThemeMenuItem: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });
  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  const fullScreenPluginInstance = fullScreenPlugin();

  const searchPluginInstance = searchPlugin();
  const { highlight, clearHighlights } = searchPluginInstance;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const [key, setKey] = useState("");
  let statement =
    "shedding light on the bigger issues surrounding socioeconomic status and its implications for \ncontinuity of care .  \nNaidoo &  Ennio n (2018) investigated the barriers of rehabilitation services and concluded that \nfinancial restrictions appeared as a substantial barrier to participants' access to hospital \nrehabilitation treatments, aggravating the difficulty they faced following lower -limb \namputation. The challenge to pay transportation to therapy appointments was a recurring";
  const search = () => {
    let lst = statement.split("\n");
    lst = lst.map((str) => str.trim());
    // console.log(lst);

    // highlight(lst);
    // jumpToPage(2);

    // highlight({
    //   keyword: lst[0],
    //   matchCase: false,
    //   wholeWords: false,
    // });
  };

  useEffect(() => {
    invokeHighlight();
  }, [highlightList, selectedFileUrl]);

  const invokeHighlight = () => {
    clearHighlights();
    if (!highlightList.length || scrollPage === "") return;
    highlight(highlightList);
    jumpToPage(scrollPage);
  };

  if (!selectedFileUrl) {
    return <></>;
  }

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      {/* <textarea onChange={(e) => setKey(e.target.value)} value={key} />
      <Button onClick={search}>search</Button> */}
      <Viewer
        fileUrl={selectedFileUrl}
        renderLoader={(percentage) => {
          return <CircularProgressWithLabel value={percentage} />;
        }}
        theme={darkMode ? "dark" : "light"}
        defaultScale={SpecialZoomLevel.PageWidth}
        plugins={[
          fullScreenPluginInstance,
          defaultLayoutPluginInstance,
          searchPluginInstance,
          pageNavigationPluginInstance,
        ]}
      />
    </Worker>
  );
}

export default PdfView;
