import React, { useContext, useState } from "react";
import "./Navbar.css";
import { ChatpdfContext } from "../../context/Context";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PaymentIcon from "@mui/icons-material/Payment";
import { ColorContext } from "../../context/ThemeContext";

function UserImg(props) {
  const navigate = useNavigate();
  const { currentUser, handleSignout } = useContext(ChatpdfContext);
  const { CustomColors } = useContext(ColorContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const signout = async () => {
    await handleSignout();
    navigate("/home");
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Avatar
          src={currentUser?.photoUrl}
          alt={currentUser?.displayName}
          sx={{ backgroundColor: CustomColors.aiChatBackground }}
        />
      </IconButton>
      <Menu
        // id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        // open={Boolean(anchorEl)}
        onClose={handleClose}
        // MenuListProps={{
        //   "aria-labelledby": "basic-button",
        // }}
        // slotProps={{ style: { width: "190px" } }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}
        >
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText>
            <p style={{ fontFamily: "Nunito Sans", margin: 5 }}>My account</p>
          </ListItemText>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            navigate("/billing");
          }}
        >
          <ListItemIcon>
            <PaymentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            <p style={{ fontFamily: "Nunito Sans", margin: 5 }}>Billing</p>
          </ListItemText>
        </MenuItem> */}
        <SignoutModal closeMenu={handleClose} />
        <p
          style={{
            fontFamily: "Nunito Sans",
            margin: "0 10px",
            fontWeight: "bold",
            color: "inherit",
          }}
        >
          {currentUser?.email}
        </p>
      </Menu>
    </>
  );
}

const SignoutModal = ({ closeMenu }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { handleSignout } = useContext(ChatpdfContext);

  const handleOpen = () => {
    // closeMenu();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signout = async () => {
    await handleSignout();
    navigate("/home");
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <p style={{ fontFamily: "Nunito Sans", margin: 5 }}>Sign Out</p>
        </ListItemText>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Sign out?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={signout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserImg;
