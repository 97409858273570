import React from 'react'
import { appName } from '../../context/constants'

const Footer = ({ appConstants }) => {
  
  return (
    <footer className="bg-[#07090d]">
  <div className="container p-6 px-4 mx-auto">
    <div className="md:flex">
      <div className="-mx-6 sm:w-2/5">
        <div className="px-6">
        
        {/* Your Footer Logo  */}
        <p className="flex title-font font-extrabold maintext items-center text-white text-3xl mb-4 md:mb-0" style={{lineHeight: "40px", cursor: "pointer"}}>
        {/* image logo */}
      {/* <img src='/logo.png' alt='logo' className=' lg:w-60 w-52' /> */}
      {/* --------------- */}
      {/* text logo */}
      {appName}
    </p>
    
    {/* sub heading */}
          <p className="max-w-sm mt-2 text-gray-500 dark:text-gray-400">Chat with your PDF documents</p>

      </div>
      </div>
      
    </div>
    <hr className="h-px my-6 bg-gray-200 border-none dark:bg-gray-700" style={{marginTop: "12px"}} />
    <div>
      <p className="text-center text-gray-500 dark:text-gray-400">© {appName} {new Date().getFullYear()} - All Rights Reserved.</p>
    </div>
  </div>
</footer>

  )
}

export default Footer