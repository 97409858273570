import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import { ChatpdfContext } from "../../context/Context";

function Signout(props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { handleSignout } = useContext(ChatpdfContext);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const signout = async () => {
    await handleSignout();
    navigate("/home");
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={handleOpen}
        sx={{
          borderRadius: "25px",
          height: "50px",
          textTransform: "none",
          fontFamily: "Nunito Sans",
          fontSize: "18px",
          width: "300px",
          marginTop: "20px",
        }}
      >
        Sign Out
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Sign out?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to sign out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={signout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Signout;
