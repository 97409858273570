import React, { useContext, useEffect, useState } from "react";
import "./File.css";
import { FaRegFilePdf } from "react-icons/fa6";
import { getFileSize, slicedFileName } from "../../context/constants";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgressWithLabel from "../../components/CircularProgressWithLabel/CircularProgressWithLabel";
import { ChatpdfContext } from "../../context/Context";

function FilePdf({ fileObj, handleRemove, uploading, setUploadResult }) {
  const { uploadFile } = useContext(ChatpdfContext);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    (async () => {
      if (!uploading) return;
      await uploadFile(
        fileObj,
        onCompleteUpload,
        "chatpdf_training",
        setProgress
      );
    })();
  }, [uploading]);

  const onCompleteUpload = (url, fileId) => {
    let fileDetail = {
      url,
      fileId: fileId,
      name: fileObj.name,
      size: fileObj.size,
      dateUploaded: Date.now().toString(),
      fileType: "pdf",
    };
    setUploadResult((prev) => [...prev, fileDetail]);
  };

  return (
    <div className="file_container">
      <div className="file_left flex_center">
        <FaRegFilePdf size={30} />
      </div>
      <div className="file_right">
        <p className="file_title">{slicedFileName(fileObj.name, 17)}</p>
        <p className="file_size">{getFileSize(fileObj.size)}</p>
      </div>
      <div className="file_far_right flex_center">
        {uploading ? (
          <CircularProgressWithLabel value={progress} />
        ) : (
          <Tooltip title="Remove" placement="left">
            <IconButton
              onClick={() => {
                handleRemove(fileObj.id);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default FilePdf;
