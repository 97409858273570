import React, { useContext, useEffect } from "react";
import "./Starter.css";
import { appName } from "../../context/constants";
import Logo from "../../components/Logo/Logo";
import {
  IconButton,
  LinearProgress,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ChatpdfContext } from "../../context/Context";
import Navbar from "../../components/Navbar/Navbar";
import { ColorContext } from "../../context/ThemeContext";
import { LogoIcon } from "../../components/Icon/Icon";
import Loader from "../../components/Loader/Loader";

function Starter(props) {
  const navigate = useNavigate();
  const { signin } = useContext(ChatpdfContext);
  const { theme, CustomColors } = useContext(ColorContext);

  useEffect(() => {
    handleStart();
  }, []);

  const handleStart = async () => {
    const uid = localStorage.getItem("ud");
    
    if (!uid) {
      navigate("/home");
      return;
    }
    let res = await signin(uid);
    if (res?.error) {
      navigate("/auth");
      return;
    }
    navigate("/documents");
  };

  return (
    <ThemeProvider theme={theme}>
      <Paper
        sx={{
          height: "100vh",
          overflowY: "hidden",
          borderRadius: "0",
          backgroundColor: CustomColors.backgroundColor,
        }}
      >
        <Navbar />
        <Loader />
        
      </Paper>
    </ThemeProvider>
  );
}

export default Starter;
