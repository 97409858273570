import React, { useRef } from "react";
import "./WorkPage.css";
import SendIcon from "@mui/icons-material/Send";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
} from "@mui/material";
import { maxChatChars } from "../../context/constants";
import { VisibilityOff } from "@mui/icons-material";

function ChatInput(props) {
  const {
    handleFormChatSubmit,
    inputRef,
    handleInputChange,
    userInput,
    handleInputHeight,
    handleSendChat,
  } = props;

  return <EffectiveInput {...props} />;

  return (
    <form className="work_form flex_center" onSubmit={handleFormChatSubmit}>
      <div className="input_container">
        <textarea
          ref={inputRef}
          onChange={handleInputChange}
          value={userInput}
          onInput={handleInputHeight}
          className="input_area"
          placeholder={"Chat with your PDFs..."}
          maxLength={maxChatChars}
        />
        <div className="input_container_right">
          <Tooltip title="Send">
            <IconButton sx={{ margin: "0 5px 10px" }} onClick={handleSendChat}>
              <SendIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </form>
  );
}

export const EffectiveInput = (props) => {
  const {
    handleFormChatSubmit,
    inputRef,
    handleInputChange,
    userInput,
    handleInputHeight,
    handleSendChat,
  } = props;

  return (
    <div className="work_form flex_center">
      <OutlinedInput
        // id="outlined-adornment-password"
        type="text"
        placeholder="Chat with your PDFs..."
        multiline
        className="effective_input"
        maxRows={10}
        // InputProps={{
        //   rows: 10,
        //   multiline: true,
        //   inputComponent: "textarea",
        // }}
        value={userInput}
        onChange={handleInputChange}
        sx={{ width: "90%" }}
        endAdornment={
          <InputAdornment position="end" sx={{ marginTop: "15px" }}>
            {/* <Tooltip title="Send"> */}
            <IconButton
              onClick={handleSendChat}
              // edge="end"
            >
              <SendIcon color="primary" />
            </IconButton>
            {/* </Tooltip> */}
          </InputAdornment>
        }
      />
    </div>
  );
};

export default ChatInput;
