import React from 'react'
import { appName } from '../../context/constants'

// Dont need to move to code , you can add or update your question/answere from inside square bracker just like other you are Seeing.






const Faqs = () => {
 
  const faq = [
  {
    id: 1,
    Question: `Is ${appName} free?`,
    Ans: `Yes. ${appName} is completely free.`,
    active: true
  },
  {
    id: 2,
    Question: 'What type of documents can I upload?',
    Ans: 'You can only upload .pdf files. However, we are working to suppport more file types in the future.',
    active: true
  },
  // {
  //   id: 3,
  //   Question: 'What kind of businesses can benefit from AI automation?',
  //   Ans: 'Although our focus is on the healthcare and wellness, any business with repetitive processes can benefit from AI automation. This includes but not limited to finance, healthcare, retail, e-commerce, customer service, logistics, etc. AI can streamline operations, enhance productivity, and help businesses make data-driven decisions.',
  //   active: false
  // },
  // {
  //   id: 4,
  //   Question: 'What tasks can be automated using AI virtual assistants?',
  //   Ans: 'AI virtual assistants, or virtual assistants, can automate a multitude of tasks in healthcare and wellness settings. For customer-facing roles, they can handle appointment scheduling, provide information on services, or answer common questions. Internally, they can assist with data management, remind staff of important tasks, or help in tracking key performance metrics. Our solutions are customizable and designed to cater to the specific needs of your healthcare or wellness business.',
  //   active: false
  // },
  {
    id: 5,
    Question: 'What data security measures do you have in place?',
    Ans: 'Data security is of utmost importance to us. We adhere strictly to industry standards and legal regulations for data handling and protection. Our systems are encrypted, and we maintain strong firewalls to protect data from breaches. Regular audits and backups are part of our security routine.',
    active: false
  }
]
  const [items, setItems] = React.useState(faq)

  const Toggle = (id) => {
    setItems((item) => item.map((i) => (
      i.id === id ? { ...i, active: !i.active } : i
    )))
  }

  return (
    <section id='faqs' className="bg-black" style={{
      backgroundColor: '#07090d',
      backgroundImage: 'radial-gradient(circle, rgba(70, 122, 255, .36), rgba(255, 255, 255, 0) 58%)'
    }}>
      <div className="container px-6 py-16 mx-auto">
        <h1 className="text-2xl font-semibold lg:text-3xl text-white text-center" style={{fontSize: "24px"}}>Frequently asked questions</h1>
        <div className="mt-8 space-y-8 lg:mt-12">
          {items.map((item) => {
            return (
              <div key={item.Question} className="lg:p-8 p-2 rounded-lg bg-gray-200" style={{ backdropFilter: 'blur(34px)', backgroundColor: 'rgba(255, 255, 255, .03)', padding: "10px 5px" }}>
                <button onClick={() => Toggle(item.id)} className="flex items-center justify-between w-full" style={{border: "none", backgroundColor: "rgba(0,0,0,0)", height: "24px", cursor: "pointer"}}>
                  <h1 className="font-semibold text-left text-white lg:text-lg">{item.Question}</h1>
                  {item.active ? (
                    <span className="text-gray-400 bg-gray-200 rounded-full" style={{height: "24px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                      </svg>
                    </span>
                  ) : (
                    <span className="text-white bg-blue-500 rounded-full" style={{height: "24px"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                    </span>
                  )}
                </button>
                {item.active && (
                  <p className="mt-6 text-sm text-gray-300">{item.Ans}</p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Faqs