import React, { useContext } from "react";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, DarkTheme, BaseProvider, styled } from "baseui";
import { StatefulInput } from "baseui/input";
import { ColorContext } from "../../context/ThemeContext";

const engine = new Styletron();

const Centered = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});

function BaseUI({ children }) {
  const { darkMode } = useContext(ColorContext);
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={darkMode ? DarkTheme : LightTheme}>
        {children}
      </BaseProvider>
    </StyletronProvider>
  );
}

export default BaseUI;
