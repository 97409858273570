import React, { useContext } from "react";
import "./App.css";
import { ColorContext } from "./context/ThemeContext";
import { Button, Paper, ThemeProvider } from "@mui/material";
import { ChatpdfContext } from "./context/Context";
import Home from "./pages/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Signin from "./pages/Auth/Signin";
import SnackBar from "./components/SnackBar/SnackBar";
import Dashboard from "./pages/Dashboard/Dashboard";
import UploadPage from "./pages/UploadPage/UploadPage";
import Starter from "./pages/Starter/Starter";
import AuthWrapper from "./pages/Auth/AuthWrapper";
import UserWrapper from "./pages/Auth/UserWrapper";
import WorkPage from "./pages/WorkPage/WorkPage";
import Profile from "./pages/Auth/Profile";

function App() {
  const { theme, CustomColors } = useContext(ColorContext);

  return (
    <Router>
      <SnackBar />
      {/* <ThemeProvider theme={theme}>
        <Paper
          sx={{
            height: "100vh",
            overflowY: "hidden",
            borderRadius: "0",
            backgroundColor: CustomColors.backgroundColor,
          }}
        > */}
      <Routes>
        <Route path="/" element={<Starter />} />
        <Route path="/home" element={<UserWrapper Page={Home} />} />
        <Route
          path="/auth"
          element={<AuthWrapper Page={Signin} inverse={true} />}
        />
        <Route path="/documents" element={<AuthWrapper Page={Dashboard} />} />
        <Route path="/upload" element={<AuthWrapper Page={UploadPage} />} />
        <Route path="/qa/:chainId" element={<AuthWrapper Page={WorkPage} />} />
        <Route path="/profile" element={<AuthWrapper Page={Profile} />} />
      </Routes>
      {/* </Paper>
      </ThemeProvider> */}
    </Router>
  );
}

export default App;
