import React, { useContext, useEffect, useState } from "react";
import "./ChatBubble.css";
import Icon, { LogoIcon } from "../../components/Icon/Icon";
import { FaLeaf } from "react-icons/fa";
import { ColorContext } from "../../context/ThemeContext";
import { Button, Chip, colors } from "@mui/material";
import { FiUser } from "react-icons/fi";
import { FadingBalls } from "react-cssfx-loading";
import { ChatpdfContext } from "../../context/Context";
import { slicedFileName } from "../../context/constants";

function ChatBubble({ chatObj, fileArray }) {
  const { CustomColors } = useContext(ColorContext);

  return (
    <>
      {chatObj?.role === "user" ? (
        <UserBubble chatObj={chatObj} fileArray={fileArray} />
      ) : (
        <AIBubble chatObj={chatObj} fileArray={fileArray} />
      )}
    </>
  );
}

const UserBubble = ({ chatObj, fileArray }) => {
  const { CustomColors, darkMode } = useContext(ColorContext);
  return (
    <div className="bubble_container">
      <div className="bubble_wrapper">
        <div className="bubble_left">
          <div
            className="role_icon_cover flex_center"
            style={{
              backgroundColor: darkMode
                ? colors.blue["200"]
                : colors.blue["700"],
            }}
          >
            <FiUser color={CustomColors.backgroundColor} size={18} />
          </div>
        </div>
        <div className="bubble_right">
          {/* <p className="bubble_paragraph">{chatObj?.content}</p> */}
          {chatObj?.content.split("\n").map((par, i) => (
            <p key={i} className="bubble_paragraph">
              {par}
              <br />
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AIBubble = ({ chatObj, fileArray, loading }) => {
  const { CustomColors } = useContext(ColorContext);
  return (
    <div className="ai_bubble_container">
      <div className="bubble_wrapper">
        <div className="bubble_left">
          <div className="role_icon_cover flex_center">
            <LogoIcon color={CustomColors.backgroundColor} size={18} />
          </div>
        </div>
        <div className="bubble_right">
          {!loading ? (
            <>
              {/* <p className="bubble_paragraph">{chatObj?.content}</p> */}
              {chatObj?.content.split("\n").map((par, i) => (
                <p key={i} className="bubble_paragraph">
                  {par}
                  <br />
                </p>
              ))}
              <SourceSection
                sourceLst={chatObj.sourceDocuments}
                fileArray={fileArray}
              />
            </>
          ) : (
            <div className="bubble_loader">
              <FadingBalls color={CustomColors.primary} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SourceSection = ({ sourceLst, fileArray }) => {
  const { setHighlightList, setSelectedFileUrl, setScrollPage, scrollPage } =
    useContext(ChatpdfContext);
  // console.log(scrollPage);
  const [selectedSource, setSelectedSource] = useState(sourceLst.length + 1);
  const [filesObj, setFilesObj] = useState({});
  useEffect(() => {
    let obj = {};
    for (let file of fileArray) {
      let fileId = file.fileId;
      obj[fileId] = file;
    }

    setFilesObj(obj);
  }, [fileArray]);

  const cleanHighlight = (sentence) => {
    sentence = sentence.split(" ");
    // console.log(sentence);
    let lst = [];
    let vowelList = ["a"];
    for (let word of sentence) {
      let lastIndex = lst.length - 1;
      if (word.length === 0) continue;
      if (word.length === 1) {
        if (!vowelList.includes(word)) continue;
        lst[lastIndex] += word;
      } else if (word.length === 1 && word[0] == "-") {
        lst[lastIndex] += word;
      } else {
        lst.push(word);
      }
    }
    return lst.join(" ");
  };

  const handleHighlight = (index, text) => {
    setSelectedSource(index);
    let lst = text.split("\n");
    lst = lst.map((sentence) => sentence.trim());
    // lst = lst.filter((sentence) => sentence.length > 0);
    lst = lst.map((sentence) => cleanHighlight(sentence));
    lst = lst.filter((sentence) => sentence.length > 0);
    // console.log(lst);

    let fileUrl =
      filesObj?.[sourceLst[index].metadata.source.split(".")[0]]?.url;
    let sourcePage = sourceLst[index].metadata.page;
    setSelectedFileUrl(fileUrl);
    setHighlightList(lst);
    setScrollPage(sourcePage);
  };
  return (
    <div
      className="sources_container"
      style={{ minHeight: fileArray.length > 1 ? "85px" : "50px" }}
    >
      {sourceLst.map((source, i) => {
        let fileName = filesObj?.[source.metadata.source.split(".")[0]]?.name;
        let filePage = source.metadata.page;
        let label =
          fileArray.length > 1
            ? `Page ${filePage + 1}, ${slicedFileName(fileName, 20)}`
            : `Page ${filePage + 1}`;
        let fullLabel =
          fileArray.length > 1
            ? `Page ${filePage + 1}, ${fileName}`
            : `Page ${filePage + 1}`;
        // console.log(source.metadata.source);
        return (
          <Chip
            key={i}
            variant={selectedSource === i ? "filled" : "outlined"}
            color="primary"
            label={label}
            title={fullLabel}
            onClick={() => {
              handleHighlight(i, source.pageContent);
            }}
            sx={{
              margin: "0 10px 10px 0",
              minWidth: "50px",
              fontFamily: "Nunito",
            }}
          />
        );
      })}
    </div>
  );
};

export default ChatBubble;
