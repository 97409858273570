import React, { useContext, useState } from "react";
import "./Signin.css";
import image from "../../assets/home1.svg";
import { appName } from "../../context/constants";
import { Button, CircularProgress } from "@mui/material";
import { FcGoogle } from "react-icons/fc";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../context/firebase";
import { ChatpdfContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import { LogoIcon } from "../../components/Icon/Icon";

function Signin(props) {
  const { setSnackbarState, signin } = useContext(ChatpdfContext);
  const navigate = useNavigate();
  const [signinLoading, setSigninLoading] = useState(false);

  const handleSignin = async () => {
    if (signinLoading) return;
    setSigninLoading(true);
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then(async (result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        result = await signin(user.uid);
        if (result.error) {
          setSnackbarState({
            open: true,
            severity: "error",
            message: result.error,
          });
          setSigninLoading(false);
          return;
        }
        if (result.firstSignin) {
          navigate("/upload");
        } else {
          navigate("/documents");
        }
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        setSigninLoading(false);
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        setSnackbarState({
          open: true,
          severity: "error",
          message: "Sign in error!",
        });
        // The email of the user's account used.
        // const email = error.customData.email;
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  return (
    <div className="auth_container">
      <div className="auth_wrapper">
        <LogoIcon size={45} />
        <h3 className="auth_title">Sign in to {appName}</h3>
        <Button
          variant="outlined"
          startIcon={<FcGoogle />}
          endIcon={signinLoading ? <CircularProgress size={20} /> : <></>}
          onClick={handleSignin}
          style={{ textTransform: "none", fontFamily: "Nunito" }}
          size="large"
        >
          {signinLoading ? "Signing you in..." : "Sign in with Google"}
        </Button>
      </div>
    </div>
  );
}

export default Signin;
